<template>
  <!--
    A grid (coordinate system) with the center being the bottom left corner of the SVG.
  -->
  <g class="zoom-point">
    <template>
      <svg
        v-for="(zoomPoint, index) in zoomMap"
        ref="zoomPointWrapper"
        :key="`zoomPoint-${ index }`"
        :index="index"
        :x="zoomPoint.pan"
        :y="-zoomPoint.tilt"
      >
        <circle
          :key="`zoomPoint-circle-${ index }`"
          r="4.2"
          :fill-opacity="0.2"
          :fill="getColor(zoomPoint)"
        />
        <text
          :key="`zoomPoint-circleTextZoom-${ index }`"
          :x="0"
          :y="1"
          :style="`font-size: ${ 0.5 }rem`"
          dominant-baseline="middle"
          text-anchor="middle"
          class="zoomPointZoom"
        >{{ zoomPoint.zoom }}</text>
      </svg>
    </template>
  </g>
</template>

<script>
export default {
  name: 'ZoomPoint',
  props: {
    zoomMap: {
      type: Array,
      required: true
    }
  },
  methods: {
    getColor (zoomPoint) {
        if(zoomPoint)
        {
            let red = zoomPoint.zoom * 2.55;
            return `rgba(${red},0,0)`;
        }
        else
        {
            return "white";
        }
    }
  }
}
</script>

<style scoped>
svg {
  overflow: visible;
}
g {
  opacity: 0.7;
}
.zoomPointIndex {
  fill: white;
  position: relative;
}
.zoomPointZoom {
  fill: white;
  position: relative;
}
</style>
